/**
 * @generated SignedSource<<78c5047101bbda7a6ca29bb99e4114e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductAdminDashboardBlockFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment" | "ProductAdminInfoToolbarFragment">;
  readonly " $fragmentType": "ProductAdminDashboardBlockFragment";
};
export type ProductAdminDashboardBlockFragment$key = {
  readonly " $data"?: ProductAdminDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductAdminDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductAdminDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductAdminInfoToolbarFragment"
    }
  ],
  "type": "ProductAdminDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "a42a5b738395488afed9086689a0d23e";

export default node;
