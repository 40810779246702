/**
 * @generated SignedSource<<250f531535379f457d9e852289c30d34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BannerDashboardBlockFragment$data = {
  readonly id: string;
  readonly showCover: boolean;
  readonly cover: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "ExperienceInfoToolbarFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "BannerDashboardBlockFragment";
};
export type BannerDashboardBlockFragment$key = {
  readonly " $data"?: BannerDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BannerDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BannerDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showCover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExperienceInfoToolbarFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "BannerDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "607ec84ff5c3d1b411a9f82604080577";

export default node;
