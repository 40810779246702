/**
 * @generated SignedSource<<49ce591436f3324128a1b476765a1f05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DashboardBlockEventsView = "card" | "list" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventsDashboardBlockFragment$data = {
  readonly eventsView: DashboardBlockEventsView;
  readonly " $fragmentSpreads": FragmentRefs<"EventsDashboardBlockCardViewFragment" | "EventsDashboardBlockListViewFragment">;
  readonly " $fragmentType": "EventsDashboardBlockFragment";
};
export type EventsDashboardBlockFragment$key = {
  readonly " $data"?: EventsDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventsDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventsDashboardBlockFragment",
  "selections": [
    {
      "alias": "eventsView",
      "args": null,
      "kind": "ScalarField",
      "name": "view",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventsDashboardBlockCardViewFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventsDashboardBlockListViewFragment"
    }
  ],
  "type": "UpcomingEventsDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "0e1b2b8334da5a6953e717242f7ffc6d";

export default node;
