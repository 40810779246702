/**
 * @generated SignedSource<<646bebcb98cfc8d30efcd5ec8c36ae81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventsDashboardBlockCardView_ProductPaginationFragment$data = {
  readonly eventOccurrences: {
    readonly __id: string;
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"OccurrenceListItemFragment">;
      };
    }>;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "EventsDashboardBlockCardView_ProductPaginationFragment";
};
export type EventsDashboardBlockCardView_ProductPaginationFragment$key = {
  readonly " $data"?: EventsDashboardBlockCardView_ProductPaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventsDashboardBlockCardView_ProductPaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "eventOccurrences"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./EventsDashboardBlockCardViewProductPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "EventsDashboardBlockCardView_ProductPaginationFragment",
  "selections": [
    {
      "alias": "eventOccurrences",
      "args": [
        {
          "kind": "Literal",
          "name": "isUpcoming",
          "value": true
        }
      ],
      "concreteType": "OccurrenceNodeConnection",
      "kind": "LinkedField",
      "name": "__EventsDashboardBlockCardView__eventOccurrences_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Occurrence",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "OccurrenceListItemFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": "__EventsDashboardBlockCardView__eventOccurrences_connection(isUpcoming:true)"
    },
    (v1/*: any*/)
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "ad981f44cbb1df3b20c782398f50f4e4";

export default node;
