/**
 * @generated SignedSource<<14e694168d36fe6b9c876f0b6d78d9ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChatChannelVisibility = "public" | "private" | "%future added value";
export type ProductAppVisibility = "all" | "admin" | "membership" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChannelsDashboardBlockItemFragment$data = {
  readonly id: string;
  readonly externalChannelId: string;
  readonly visibility: ChatChannelVisibility;
  readonly productApp: {
    readonly customAppTitle: string | null;
    readonly visibility: ProductAppVisibility;
    readonly ordering: number;
    readonly badge: {
      readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
    };
  } | null;
  readonly " $fragmentType": "ChannelsDashboardBlockItemFragment";
};
export type ChannelsDashboardBlockItemFragment$key = {
  readonly " $data"?: ChannelsDashboardBlockItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChannelsDashboardBlockItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChannelsDashboardBlockItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalChannelId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "productApp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customAppTitle",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ordering",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Badge",
          "kind": "LinkedField",
          "name": "badge",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BadgeFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ChatChannel",
  "abstractKey": null
};
})();

(node as any).hash = "6f1d4be28165649f5f220c0b65393f21";

export default node;
