/**
 * @generated SignedSource<<bfa60c4f9bfab274c56507852af6eac7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentDashboardBlockFullContentFragment$data = {
  readonly contentUsages: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly content: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"ContentCard_ContentFragment" | "ContentTitleSectionFragment">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"ContentCard_ContentUsageFragment">;
      };
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ContentDashboardBlockAdminDropdownFragment">;
  readonly " $fragmentType": "ContentDashboardBlockFullContentFragment";
};
export type ContentDashboardBlockFullContentFragment$key = {
  readonly " $data"?: ContentDashboardBlockFullContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentDashboardBlockFullContentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentDashboardBlockFullContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsageNodeConnection",
      "kind": "LinkedField",
      "name": "contentUsages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ContentCard_ContentUsageFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Content",
                  "kind": "LinkedField",
                  "name": "content",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ContentCard_ContentFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ContentTitleSectionFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentDashboardBlockAdminDropdownFragment"
    }
  ],
  "type": "ContentDashboardBlock",
  "abstractKey": null
};
})();

(node as any).hash = "9967d07e9fae1eae702e00803a7b4a94";

export default node;
