import { useLabels } from "@/core/context/LabelsContext"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { DiscoFormControl, DiscoInput } from "@disco-ui"
import DiscoImageFormField from "@disco-ui/file/DiscoImageFormField"
import { observer } from "mobx-react-lite"

interface Props {
  form: DashboardBlockFormStore
}

function CommunityWelcomeHeroDashboardBlockForm({ form }: Props) {
  const labels = useLabels()
  const { communityWelcomeHero } = form.state
  if (!communityWelcomeHero) return null
  return (
    <>
      <DiscoFormControl label={"Title"}>
        <DiscoInput
          placeholder={"i.e. Your community name"}
          defaultValue={communityWelcomeHero.title}
          onChange={(e) => (communityWelcomeHero.title = e.target.value)}
          data-testid={"CommunityWelcomeHeroDashboardBlockForm.title"}
        />
      </DiscoFormControl>
      <DiscoFormControl label={"Description"}>
        <DiscoInput
          placeholder={`Introduce your community or welcome your ${labels.organization_member.plural}`}
          defaultValue={communityWelcomeHero.subtitle}
          onChange={(e) => (communityWelcomeHero.subtitle = e.target.value)}
          multiline
          data-testid={"CommunityWelcomeHeroDashboardBlockForm.subtitle"}
        />
      </DiscoFormControl>
      <DiscoFormControl label={"Cover Image"}>
        <DiscoImageFormField
          value={communityWelcomeHero.cover}
          onChange={(v) => {
            communityWelcomeHero.cover = v?.url || null
            communityWelcomeHero.coverAssetId = v?.id || null
          }}
          alterAspectRatio={false}
          testid={"CommunityWelcomeHeroDashboardBlockForm.cover"}
        />
      </DiscoFormControl>
    </>
  )
}

export default observer(CommunityWelcomeHeroDashboardBlockForm)
