/**
 * @generated SignedSource<<d7e3f97d62ff13807be5193ef53a453a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CurriculumDashboardBlockCardViewFragment$data = {
  readonly productId: string | null;
  readonly organizationId: string;
  readonly position: DashboardBlockPosition;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "CurriculumDashboardBlockCardViewFragment";
};
export type CurriculumDashboardBlockCardViewFragment$key = {
  readonly " $data"?: CurriculumDashboardBlockCardViewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurriculumDashboardBlockCardViewFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurriculumDashboardBlockCardViewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "CurriculumDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "07613e673a23d48e07dd9b8b6c226814";

export default node;
