import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { DiscoAlert, DiscoDivider, DiscoFormControl, DiscoInput } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form: DashboardBlockFormStore
}

function ChannelsDashboardBlockForm(props: Props) {
  const { form } = props
  const { channels } = form.state

  if (!channels) return null

  return (
    <>
      <DiscoAlert
        message={
          "Only one Channels block can be added. This block will aggregate all channels across your experience."
        }
      />
      <DiscoDivider marginTop={2.5} marginBottom={2} />
      <DiscoFormControl label={"Title"} errorMessages={form.errorsByField.title}>
        <DiscoInput
          value={channels.title}
          onChange={(e) => (channels.title = e.target.value)}
          fullWidth
        />
      </DiscoFormControl>
    </>
  )
}

export default observer(ChannelsDashboardBlockForm)
