/**
 * @generated SignedSource<<9c849e4e6879636873be23834a667f42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
export type EventsDashboardBlockListViewQuery$variables = {
  id: string;
  first?: number | null;
  productId: string;
};
export type EventsDashboardBlockListViewQuery$data = {
  readonly organization: {
    readonly occurrences?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly product: {
            readonly type: ProductType;
          };
          readonly " $fragmentSpreads": FragmentRefs<"EventsDashboardBlockListItem">;
        };
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  } | null;
  readonly product: {
    readonly slug?: string;
    readonly eventOccurrences?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"EventsDashboardBlockListItem">;
        };
      }>;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  } | null;
};
export type EventsDashboardBlockListViewQuery = {
  variables: EventsDashboardBlockListViewQuery$variables;
  response: EventsDashboardBlockListViewQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v5 = [
  {
    "kind": "Literal",
    "name": "datetimeFilter",
    "value": "upcoming"
  },
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "EventsDashboardBlockListItem"
},
v9 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "usePermissionsFragment"
},
v10 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "productId"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v12 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "isUpcoming",
    "value": true
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "datetimeRange",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Event",
  "kind": "LinkedField",
  "name": "event",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRecurring",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v18 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerPermissions",
    "storageKey": null
  }
],
v19 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v18/*: any*/),
      "type": "Organization",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v18/*: any*/),
      "type": "Product",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v18/*: any*/),
      "type": "Event",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v18/*: any*/),
      "type": "Comment",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v18/*: any*/),
      "type": "Feed",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v18/*: any*/),
      "type": "Post",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v18/*: any*/),
      "type": "ContentUsage",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v18/*: any*/),
      "type": "ChatChannel",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v18/*: any*/),
      "type": "Dashboard",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v18/*: any*/),
      "type": "AssignmentSubmission",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v18/*: any*/),
      "type": "PathwayGroup",
      "abstractKey": null
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventsDashboardBlockListViewQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "OccurrenceNodeConnection",
                "kind": "LinkedField",
                "name": "occurrences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OccurrenceNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Occurrence",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "product",
        "args": (v10/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "OccurrenceNodeConnection",
                "kind": "LinkedField",
                "name": "eventOccurrences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OccurrenceNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Occurrence",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EventsDashboardBlockListViewQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "OccurrenceNodeConnection",
                "kind": "LinkedField",
                "name": "occurrences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OccurrenceNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Occurrence",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v19/*: any*/)
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "product",
        "args": (v10/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "OccurrenceNodeConnection",
                "kind": "LinkedField",
                "name": "eventOccurrences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OccurrenceNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Occurrence",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v19/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "216e2d4342aa10a43f88025681921c77",
    "id": null,
    "metadata": {},
    "name": "EventsDashboardBlockListViewQuery",
    "operationKind": "query",
    "text": "query EventsDashboardBlockListViewQuery(\n  $id: ID!\n  $first: Int\n  $productId: ID!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      occurrences(first: $first, datetimeFilter: upcoming) {\n        edges {\n          node {\n            id\n            product {\n              type\n              id\n            }\n            ...EventsDashboardBlockListItem\n          }\n        }\n      }\n      ...usePermissionsFragment\n    }\n    id\n  }\n  product: node(id: $productId) {\n    __typename\n    ... on Product {\n      slug\n      eventOccurrences(first: $first, isUpcoming: true) {\n        edges {\n          node {\n            id\n            ...EventsDashboardBlockListItem\n          }\n        }\n      }\n      ...usePermissionsFragment\n    }\n    id\n  }\n}\n\nfragment EventsDashboardBlockListItem on Occurrence {\n  id\n  datetimeRange\n  status\n  ...OccurrenceNameAndTimeFragment\n}\n\nfragment OccurrenceNameAndTimeFragment on Occurrence {\n  id\n  name\n  datetimeRange\n  event {\n    isRecurring\n    id\n  }\n}\n\nfragment usePermissionsFragment on Node {\n  __isNode: __typename\n  __typename\n  ... on Organization {\n    viewerPermissions\n  }\n  ... on Product {\n    viewerPermissions\n  }\n  ... on Event {\n    viewerPermissions\n  }\n  ... on Comment {\n    viewerPermissions\n  }\n  ... on Feed {\n    viewerPermissions\n  }\n  ... on Post {\n    viewerPermissions\n  }\n  ... on ContentUsage {\n    viewerPermissions\n  }\n  ... on ChatChannel {\n    viewerPermissions\n  }\n  ... on Dashboard {\n    viewerPermissions\n  }\n  ... on AssignmentSubmission {\n    viewerPermissions\n  }\n  ... on PathwayGroup {\n    viewerPermissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "9f9b440c18e7edc994cc0be8ca486437";

export default node;
