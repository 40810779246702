import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { DashboardBlockEventsView } from "@/dashboard/blocks/kinds/__generated__/EventsDashboardBlockFragment.graphql"
import CarouselView from "@assets/images/covers/dashboard-blocks/carousel-view.svg"
import ListView from "@assets/images/covers/dashboard-blocks/list-view.svg"
import { DiscoCheckbox, DiscoFormControl, DiscoInput } from "@disco-ui"
import DiscoRadioWithImage from "@disco-ui/radio/DiscoRadioWithImage"
import { RadioGroup } from "@material-ui/core"
import { observer } from "mobx-react-lite"

interface Props {
  form: DashboardBlockFormStore
}

function EventsDashboardBlockForm({ form }: Props) {
  const { upcomingEvents: events } = form.state
  const activeProduct = useActiveProduct()
  const classes = useStyles()
  const productLabel = useLabel("admin_experience")

  if (!events) return null

  return (
    <>
      <DiscoFormControl
        label={`What style of ${
          activeProduct ? productLabel.singular : "Community"
        } events do you want to show?`}
        className={classes.radioFormControl}
      >
        <RadioGroup
          value={events.view}
          onChange={(e) => (events.view = e.target.value as DashboardBlockEventsView)}
          className={classes.radioGroup}
        >
          <DiscoRadioWithImage
            testid={`EventsDashboardBlockForm.radio.card`}
            label={"Carousel"}
            sublabel={<CarouselView />}
            value={"card"}
            checked={events.view === "card"}
          />

          <DiscoRadioWithImage
            testid={`EventsDashboardBlockForm.radio.list`}
            label={"List"}
            sublabel={<ListView />}
            value={"list"}
            checked={events.view === "list"}
          />
        </RadioGroup>
      </DiscoFormControl>

      {events.view === "list" && (
        <DiscoFormControl
          label={`How many upcoming ${
            activeProduct ? productLabel.singular : "Community"
          } events do you wish to show on this block?`}
        >
          <DiscoInput
            data-testid={"events.count-input"}
            type={"number"}
            onChange={(e) => (events.count = Number(e.target.value) || null)}
            value={events.count}
            fullWidth
          />
        </DiscoFormControl>
      )}

      {events.view === "card" && (
        <DiscoFormControl>
          <DiscoCheckbox
            label={`Show event cover images`}
            checked={Boolean(events.showCover)}
            onChange={() => (events.showCover = !events.showCover)}
          />
        </DiscoFormControl>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  radioFormControl: {
    flexWrap: "nowrap",
  },
  radioGroup: {
    gap: theme.spacing(2),
    display: "grid",
    gridAutoFlow: "column",
    [theme.breakpoints.down("xs")]: {
      gridAutoFlow: "row",
    },
  },
}))

export default observer(EventsDashboardBlockForm)
