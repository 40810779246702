import { createContext, ReactNode, useCallback, useContext, useMemo } from "react"

type DashboardEvent = "unpinned_post" | "pinned_post" | "refetch_feed_card_view"

type DashboardEventContextValue = {
  dispatchEvent: (event: DashboardEvent) => void
  register: (event: DashboardEvent, listener: VoidFunction) => UnsubscribeFunction
}

type UnsubscribeFunction = VoidFunction
type EventListeners = Record<string, Array<VoidFunction>>

const DashboardEventContext = createContext<DashboardEventContextValue | null>(null)

interface DashboardEventProviderProps {
  children: ReactNode
}

export const useDashboardEvent = (): DashboardEventContextValue => {
  const context = useContext(DashboardEventContext)
  return context!
}

function DashboardEventProvider({ children }: DashboardEventProviderProps) {
  const globalListeners: EventListeners = useMemo(() => {
    return {}
  }, [])

  /** Register a event to the global listeners, if the event type doesn't exist, create a new entry */
  const register = useCallback(
    (event: string, listener: VoidFunction) => {
      // Remove the listener from the global listeners
      const unsubscribe: UnsubscribeFunction = () => {
        globalListeners[event] = globalListeners[event].filter((l) => l !== listener)
      }

      const eventListeners = globalListeners[event]
      if (eventListeners) {
        // Add the listener to the existing event listeners
        eventListeners.push(listener)
      } else {
        // Create a new event listener
        globalListeners[event] = [listener]
      }

      return unsubscribe
    },
    [globalListeners]
  )
  const dispatchEvent = useCallback(
    (event: string) => {
      const eventListeners = globalListeners[event]
      // Ignore invalid events
      if (!eventListeners) return

      // Handle the event
      eventListeners.forEach((listener) => listener())
    },
    [globalListeners]
  )

  return (
    <DashboardEventContext.Provider
      value={{
        register,
        dispatchEvent,
      }}
    >
      {children}
    </DashboardEventContext.Provider>
  )
}

export default DashboardEventProvider
