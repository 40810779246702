import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { DashboardBlockLeaderboardView } from "@/dashboard/edit/__generated__/EditDashboardBlockFormFragment.graphql"
import CarouselView from "@assets/images/covers/dashboard-blocks/carousel-view.svg"
import ScrollView from "@assets/images/covers/dashboard-blocks/scroll-view.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoFormControl, DiscoInput, DiscoSelect } from "@disco-ui"
import DiscoRadioWithImage from "@disco-ui/radio/DiscoRadioWithImage"
import { RadioGroup } from "@material-ui/core"
import { observer } from "mobx-react-lite"

interface Props {
  form: DashboardBlockFormStore
}

function LeaderboardDashboardBlockForm(props: Props) {
  const activeProduct = useActiveProduct()
  const classes = useStyles()
  const { form } = props

  const labels = useLabels()
  const memberLabel = labels.admin_member
  const productLabel = labels.admin_experience

  const { leaderboard } = form.state
  if (!leaderboard) return null

  let memberCountTooltipMessage = `The leaderboard is populated when there's at least 3 ${memberLabel.plural} with scores higher than 0 to show. This block shows the top ${memberLabel.plural} based on their activity in the specified time window below.`
  if (activeProduct)
    memberCountTooltipMessage += ` The score is limited to activity only within this ${productLabel.singular}.`
  else
    memberCountTooltipMessage += ` The score is calculated based on activity across the entire community and the ${productLabel.plural} within it.`

  return (
    <>
      <DiscoDivider marginTop={2} marginBottom={2} />
      <DiscoFormControl label={"Title"}>
        <DiscoInput
          value={leaderboard.title}
          onChange={(e) => (leaderboard.title = e.target.value ? e.target.value : null)}
        />
      </DiscoFormControl>
      <DiscoFormControl
        label={"Description"}
        tooltip={`This description is shown on the top right of the block as a tooltip and is visible to ${memberLabel.plural}.`}
      >
        <DiscoInput
          value={leaderboard.description}
          onChange={(e) =>
            (leaderboard.description = e.target.value ? e.target.value : null)
          }
        />
      </DiscoFormControl>
      <DiscoFormControl>
        <DiscoFormControl label={"Leaderboard style"}>
          <RadioGroup
            value={leaderboard.view}
            onChange={(e) =>
              (leaderboard.view = e.target.value as DashboardBlockLeaderboardView)
            }
            className={classes.radioGroup}
          >
            <DiscoRadioWithImage
              testid={"LeaderboardDashboardBlockForm.radio.card"}
              className={classes.radioButton}
              label={"Carousel"}
              sublabel={<CarouselView />}
              value={"card"}
              checked={leaderboard.view === "card"}
            />

            <DiscoRadioWithImage
              testid={`LeaderboardDashboardBlockForm.radio.list`}
              className={classes.radioButton}
              label={"List"}
              sublabel={<ScrollView />}
              value={"list"}
              checked={leaderboard.view === "list"}
            />
          </RadioGroup>
        </DiscoFormControl>
      </DiscoFormControl>

      <DiscoFormControl
        label={`${memberLabel.plural} to show`}
        tooltip={memberCountTooltipMessage}
      >
        <DiscoInput
          placeholder={"4"}
          type={"number"}
          value={leaderboard.count}
          onChange={(e) =>
            (leaderboard.count = e.target.value ? parseInt(e.target.value) : null)
          }
        />
      </DiscoFormControl>
      <DiscoFormControl
        label={"Time period"}
        tooltip={
          "For the week-based options, first day of the week is Monday. All times are in UTC."
        }
      >
        <DiscoSelect
          value={leaderboard.lookbackWindow}
          onChange={(v) => (leaderboard.lookbackWindow = v!)}
          options={[
            { value: "last_7_days", title: "Last 7 days" },
            { value: "last_30_days", title: "Last 30 days" },
            { value: "last_90_days", title: "Last 90 days" },
            { value: "last_365_days", title: "Last 365 days" },
            { value: "this_week", title: "This week" },
            { value: "last_week", title: "Last week" },
            { value: "this_month", title: "This month" },
            { value: "last_month", title: "Last month" },
            { value: "this_year", title: "This year" },
            { value: "last_year", title: "Last year" },
          ]}
          disableClearable
        />
      </DiscoFormControl>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  radioGroup: {
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: theme.spacing(2),
    display: "grid",
    gridAutoFlow: "column",
    flex: "1 1 auto",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  radioButton: {
    flexGrow: 1,
  },
}))

export default observer(LeaderboardDashboardBlockForm)
