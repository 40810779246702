import ContentSelect from "@/admin/content-library/actions/ContentSelect"
import ContentUtils from "@/content/util/contentUtils"
import { ObservableState } from "@/core/form/store/FormStore"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import {
  ContentDashboardBlockInput,
  DashboardBlockContentMode,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import CarouselView from "@assets/images/covers/dashboard-blocks/carousel-view.svg"
import ContentFullContent from "@assets/images/covers/dashboard-blocks/content-full-content.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl } from "@disco-ui"
import DiscoRadioWithImage from "@disco-ui/radio/DiscoRadioWithImage"
import { RadioGroup } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"

interface Props {
  form: DashboardBlockFormStore
}

function ContentDashboardBlockForm(props: Props) {
  const { form } = props

  const classes = useStyles()

  const content = form.state.content as ObservableState<ContentDashboardBlockInput>

  useEffect(() => {
    if (!form.changedState.content) return
    content.contentIds?.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.changedState.content?.mode])

  if (!content) return null

  return (
    <>
      <DiscoFormControl label={"What layout do you want to show content in?"}>
        <RadioGroup
          value={content.mode}
          onChange={(e) => (content.mode = e.target.value as DashboardBlockContentMode)}
          className={classes.radioGroup}
        >
          <DiscoRadioWithImage
            testid={`ContentDashboardBlockForm.radio.preview`}
            className={classes.radioButton}
            label={"Carousel"}
            sublabel={<CarouselView />}
            value={"preview"}
            checked={content.mode === "preview"}
          />

          <DiscoRadioWithImage
            testid={`ContentDashboardBlockForm.radio.full`}
            className={classes.radioButton}
            label={"Full Content"}
            sublabel={<ContentFullContent />}
            value={"full"}
            checked={content.mode === "full"}
          />
        </RadioGroup>
      </DiscoFormControl>

      <DiscoFormControl
        label={
          content.mode === "preview"
            ? "Select one or more contents to display in this block"
            : "Select a content to display in this block"
        }
        error={Boolean(form.errorsByField.contentIds)}
        errorMessages={form.errorsByField.contentIds}
      >
        <ContentSelect
          typesToExclude={ContentUtils.getTypesToExclude("dashboard_block_content")}
          values={content.contentIds || []}
          onSelect={handleContentSelect}
          limit={form.state.content?.mode === "full" ? 1 : undefined}
        />
      </DiscoFormControl>
    </>
  )

  function handleContentSelect(values: GlobalID[]) {
    form.state.content!.contentIds = values
  }
}

const useStyles = makeUseStyles((theme) => ({
  radioGroup: {
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: "grid",
    gridAutoFlow: "column",
    flex: "1 1 auto",
    gridTemplateColumns: "1fr 1fr",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  radioButton: {
    flexGrow: 1,
  },
}))

export default observer(ContentDashboardBlockForm)
