import ModuleSelect from "@/admin/content-library/actions/ModuleSelect"
import { ObservableState } from "@/core/form/store/FormStore"
import { CollectionFolderDashboardBlockInput } from "@/dashboard/add/__generated__/AddDashboardBlockModalMutation.graphql"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoFormControl } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form: DashboardBlockFormStore
}

function CollectionFolderDashboardBlockForm(props: Props) {
  const { form } = props

  const collectionFolder = form.state
    .collectionFolder as ObservableState<CollectionFolderDashboardBlockInput>

  if (!collectionFolder) return null

  return (
    <>
      <DiscoFormControl
        label={"Select a content to display in this block"}
        error={Boolean(form.errorsByField.contentIds)}
        errorMessages={form.errorsByField.contentIds}
      >
        <ModuleSelect
          value={collectionFolder.contentId || ""}
          onSelect={handleModuleSelect}
        />
      </DiscoFormControl>
    </>
  )

  function handleModuleSelect(contentId: GlobalID) {
    form.state.collectionFolder!.contentId = contentId
  }
}

export default observer(CollectionFolderDashboardBlockForm)
