import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabels } from "@/core/context/LabelsContext"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import {
  DiscoCheckbox,
  DiscoDivider,
  DiscoFormControl,
  DiscoInput,
  DiscoLink,
  DiscoText,
} from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form: DashboardBlockFormStore
  onClose: () => void
}

function ExperienceDetailsDashboardBlockForm(props: Props) {
  const { form, onClose } = props

  const labels = useLabels()
  const activeProduct = useActiveProduct()
  const settingsDrawer = useGlobalDrawer("experienceSettings")

  const { experienceDetails } = form.state

  if (!experienceDetails) return null

  return (
    <>
      <DiscoText variant={"body-sm"} display={"inline"} color={"text.secondary"}>
        {`Experience Details on this block such as cover image, start & end date, etc. are pulled from your `}
        <DiscoLink onClick={handleOpenSettings}>
          <DiscoText
            variant={"body-sm"}
            color={"primary.main"}
            component={"span"}
          >{`${labels.experience.singular} Settings.`}</DiscoText>
        </DiscoLink>
      </DiscoText>

      <DiscoDivider marginTop={2} marginBottom={2} />

      <DiscoFormControl label={"Description"}>
        <DiscoInput
          multiline
          minRows={3}
          maxRows={3}
          value={experienceDetails.description ?? ""}
          onChange={(e) => (experienceDetails.description = e.target.value)}
          placeholder={`Tell your ${labels.product_member.plural} what they should expect from this ${labels.experience.singular}.`}
        />
      </DiscoFormControl>

      <DiscoFormControl marginBottom={0.25}>
        <DiscoCheckbox
          label={`Show number of ${labels.product_member.plural} who join this ${labels.experience.singular}.`}
          checked={Boolean(experienceDetails.showMembers)}
          onChange={() =>
            (experienceDetails.showMembers = !experienceDetails.showMembers)
          }
        />
      </DiscoFormControl>

      <DiscoFormControl marginBottom={0.25}>
        <DiscoCheckbox
          label={`Show start date & end date.`}
          checked={Boolean(experienceDetails.showDates)}
          onChange={() => (experienceDetails.showDates = !experienceDetails.showDates)}
        />
      </DiscoFormControl>

      <DiscoFormControl marginBottom={0.25}>
        <DiscoCheckbox
          label={`Show countdown timer before start date.`}
          checked={Boolean(experienceDetails.showCountdown)}
          onChange={() =>
            (experienceDetails.showCountdown = !experienceDetails.showCountdown)
          }
        />
      </DiscoFormControl>
    </>
  )

  function handleOpenSettings() {
    onClose()
    settingsDrawer.open({
      experienceSettingsTab: "details",
      drawerExperienceId: activeProduct?.id,
    })
  }
}

export default observer(ExperienceDetailsDashboardBlockForm)
