/**
 * @generated SignedSource<<16377142ce362dde1666206096385b13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DashboardBlockMembersListMemberType = "member_only" | "admin_only" | "all" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MembersListDashboardBlockFragment$data = {
  readonly organizationId: string;
  readonly product: {
    readonly id: string;
    readonly slug: string;
  } | null;
  readonly memberCount: number;
  readonly memberType: DashboardBlockMembersListMemberType;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "MembersListDashboardBlockFragment";
};
export type MembersListDashboardBlockFragment$key = {
  readonly " $data"?: MembersListDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MembersListDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembersListDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "memberCount",
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memberType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "MembersListDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "b22e963a3ab4ae77ec108d155291feb1";

export default node;
