import { useLabels } from "@/core/context/LabelsContext"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { DiscoDivider, DiscoFormControl, DiscoInput } from "@disco-ui"
import DiscoImageFormField from "@disco-ui/file/DiscoImageFormField"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import { observer } from "mobx-react-lite"

interface Props {
  form: DashboardBlockFormStore
}

function WelcomeBannerDashboardBlockForm(props: Props) {
  const { form } = props

  const classes = useStyles()
  const labels = useLabels()

  const { welcomeBanner } = form.state
  if (!welcomeBanner) return null

  return (
    <>
      <DiscoFormControl label={"Title"}>
        <DiscoInput
          placeholder={"i.e. Your Community Tagline"}
          defaultValue={welcomeBanner.title}
          onChange={(e) => (welcomeBanner.title = e.target.value)}
          data-testid={"WelcomeBannerDashboardBlockForm.title"}
        />
      </DiscoFormControl>
      <DiscoFormControl label={"Subtitle"}>
        <DiscoInput
          placeholder={"i.e. Your Community Name"}
          defaultValue={welcomeBanner.subtitle}
          onChange={(e) => (welcomeBanner.subtitle = e.target.value)}
          data-testid={"WelcomeBannerDashboardBlockForm.subtitle"}
        />
      </DiscoFormControl>
      <DiscoFormControl label={"Body Text"}>
        <DiscoEditor
          className={classes.bodyTextInput}
          placeholder={`Introduce your community or welcome your ${labels.organization_member.plural}`}
          defaultValue={welcomeBanner.richEditorDescription}
          onChange={(v) => (welcomeBanner.richEditorDescription = v)}
          testid={"WelcomeBannerDashboardBlockForm.body-text"}
        />
      </DiscoFormControl>
      <DiscoFormControl label={"Cover Image"}>
        <DiscoImageFormField
          value={welcomeBanner.cover}
          onChange={(v) => {
            welcomeBanner.cover = v!.url
            welcomeBanner.coverAssetId = v!.id
          }}
          disableRemove
          testid={"WelcomeBannerDashboardBlockForm"}
          cropperProps={{
            stencilProps: { aspectRatio: ASPECT_RATIOS.COVER_PHOTO },
          }}
          suggestedDimensions={{ width: 1200, height: 600 }}
          alterAspectRatio={false}
        />
      </DiscoFormControl>
      <DiscoDivider />
      {/* <DiscoFormControl>
        <DiscoCheckbox
          label={"Turn off this banner on a set date"}
          checked={Boolean(welcomeBanner.hideAfter)}
          onChange={(v) => {
            welcomeBanner.hideAfter = v
              ? format(addDays(new Date(), 7), DATE_FORMAT.API_FORMAT)
              : null
          }}
        />
      </DiscoFormControl>
      {welcomeBanner.hideAfter && (
        <DiscoSection groovyDepths={"insideCard"} marginBottom={2.5}>
          <DiscoFormControl label={"Date"} marginBottom={0}>
            <DateSelect
              testid={"WelcomeBannerDashboardBlockForm.hide-after-date"}
              onSelect={(v) => {
                welcomeBanner.hideAfter = format(v as Date, DATE_FORMAT.API_FORMAT)
              }}
              selectedDate={compensateForTimeZone(new Date(welcomeBanner.hideAfter))}
            />
          </DiscoFormControl>
        </DiscoSection>
      )} */}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  bodyTextInput: {
    borderRadius: theme.measure.borderRadius.big,
    border: "2px solid transparent",

    backgroundColor: theme.palette.groovy.neutral[100],
    "&.Mui-focused": {
      backgroundColor: theme.palette.background.paper,
      border: `2px solid ${theme.palette.primary.main}`,
    },

    minHeight: "100px",
  },
}))

export default observer(WelcomeBannerDashboardBlockForm)
