import { useLabels } from "@/core/context/LabelsContext"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { DashboardBlockCurriculumView } from "@/dashboard/edit/__generated__/EditDashboardBlockFormFragment.graphql"
import CarouselView from "@assets/images/covers/dashboard-blocks/carousel-view.svg"
import ListView from "@assets/images/covers/dashboard-blocks/list-view.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoAlert, DiscoDivider, DiscoFormControl, DiscoInput } from "@disco-ui"
import DiscoRadioWithImage from "@disco-ui/radio/DiscoRadioWithImage"
import { RadioGroup } from "@material-ui/core"
import { observer } from "mobx-react-lite"

interface Props {
  form: DashboardBlockFormStore
}

function CurriculumDashboardBlockForm(props: Props) {
  const { form } = props
  const { curriculum } = form.state
  const { product_member, curriculum: curriculumLabel } = useLabels()
  const classes = useStyles()

  if (!curriculum) return null

  return (
    <>
      <DiscoAlert
        message={`Only one ${curriculumLabel.singular} block can be added. This block will show ${product_member.plural} the most recent Module they have not completed`}
      />
      <DiscoDivider marginTop={2.5} marginBottom={2} />
      <DiscoFormControl label={"Title"} errorMessages={form.errorsByField.title}>
        <DiscoInput
          data-testid={"CurriculumDashboardBlockForm.title"}
          value={curriculum.title}
          onChange={(e) => (curriculum.title = e.target.value)}
          fullWidth
        />
      </DiscoFormControl>
      <DiscoFormControl
        label={`Select a view style for the ${curriculumLabel.singular}`}
        className={classes.radioFormControl}
      >
        <RadioGroup
          value={curriculum.view}
          onChange={(e) =>
            (curriculum.view = e.target.value as DashboardBlockCurriculumView)
          }
          className={classes.radioGroup}
        >
          <DiscoRadioWithImage
            testid={"CurriculumDashboardBlockForm.radio.card"}
            label={"Carousel"}
            sublabel={<CarouselView />}
            value={"card"}
            checked={curriculum.view === "card"}
          />
          <DiscoRadioWithImage
            testid={"CurriculumDashboardBlockForm.radio.list"}
            label={"List"}
            sublabel={<ListView />}
            value={"list"}
            checked={curriculum.view === "list"}
          />
        </RadioGroup>
      </DiscoFormControl>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  radioFormControl: {
    flexWrap: "nowrap",
  },
  radioGroup: {
    gap: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
}))

export default observer(CurriculumDashboardBlockForm)
