import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import DiscoEditor from "@components/editor/DiscoEditor"
import {
  DiscoCheckbox,
  DiscoDivider,
  DiscoFormControl,
  DiscoInput,
  DiscoSection,
} from "@disco-ui"
import DiscoTitleInput from "@disco-ui/input/DiscoTitleInput"
import { Grid } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { graphql } from "relay-runtime"

interface Props {
  form: DashboardBlockFormStore
}

function RichTextDashboardBlockForm(props: Props) {
  const { form } = props
  const { richText } = form.state
  if (!richText) return null
  return (
    <>
      <DiscoFormControl
        errorMessages={form.errorsByField.title}
        error={Boolean(form.errorsByField.title?.length)}
      >
        <DiscoTitleInput
          value={richText.title}
          fullWidth
          onChange={(e) => (richText.title = e.target.value)}
          placeholder={"Title"}
          data-testid={"RichTextDashboardBlockForm.title"}
        />
      </DiscoFormControl>
      <DiscoEditor
        defaultValue={richText.richEditorContent}
        onChange={(v) => (richText.richEditorContent = v)}
        placeholder={"Share something great..."}
      />
      <DiscoDivider marginBottom={2.5} marginTop={2.5} />
      <DiscoFormControl
        errorMessages={form.errorsByField.hasCtaButton}
        error={Boolean(form.errorsByField.hasCtaButton?.length)}
      >
        <DiscoCheckbox
          label={"Show CTA button"}
          checked={richText.hasCtaButton!}
          onChange={(checked) => (richText.hasCtaButton = checked)}
        />
      </DiscoFormControl>
      {richText.hasCtaButton && (
        <DiscoSection groovyDepths={"insideCard"}>
          <Grid container xs spacing={1}>
            <Grid item xs={12} sm={6}>
              <DiscoFormControl
                label={"Label"}
                errorMessages={form.errorsByField.ctaText}
                error={Boolean(form.errorsByField.ctaText?.length)}
                marginBottom={0}
              >
                <DiscoInput
                  value={richText.ctaText}
                  fullWidth
                  onChange={(e) => (richText.ctaText = e.target.value)}
                  placeholder={"Register Now!"}
                />
              </DiscoFormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DiscoFormControl
                label={"Link"}
                errorMessages={form.errorsByField.ctaUrl}
                error={Boolean(form.errorsByField.ctaUrl?.length)}
                marginBottom={0}
              >
                <DiscoInput
                  value={richText.ctaUrl}
                  fullWidth
                  onChange={(e) => (richText.ctaUrl = e.target.value)}
                  placeholder={"https://www.disco.co"}
                />
              </DiscoFormControl>
            </Grid>
          </Grid>
        </DiscoSection>
      )}
    </>
  )
}

export default observer(RichTextDashboardBlockForm)

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment RichTextDashboardBlockFormFragment on RichTextDashboardBlock {
    position
    richTextBlockTitle: title
    richEditorContent
    hasCtaButton
    ctaUrl
    ctaText
  }
`
