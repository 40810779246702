import { useDashboardBlockKindForms } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import EditDashboardBlockForm from "@/dashboard/edit/EditDashboardBlockForm"
import { EditDashboardBlockButtonFragment$key } from "@/dashboard/edit/__generated__/EditDashboardBlockButtonFragment.graphql"
import { DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { useCallback } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends OverridableDiscoButtonProps {
  dashboardBlockKey: EditDashboardBlockButtonFragment$key
}

function EditDashboardBlockButton(props: Props) {
  const { children, dashboardBlockKey, ...rest } = props

  const dashboardBlock = useFragment<EditDashboardBlockButtonFragment$key>(
    graphql`
      fragment EditDashboardBlockButtonFragment on DashboardBlock {
        kind
        position
        ...EditDashboardBlockFormFragment
      }
    `,
    dashboardBlockKey
  )

  const kinds = useDashboardBlockKindForms(dashboardBlock.position)
  const kindConfig = kinds[dashboardBlock.kind as keyof typeof kinds]
  const name = kindConfig?.name || "Block"

  const stopPropagation = (event: { stopPropagation: () => void }) => {
    // prevent propagation to parent dashboard block to allow dragging editor blocks
    event.stopPropagation()
  }

  return (
    <OverridableDiscoButton
      {...rest}
      modal={useCallback(
        ({ isOpen, onClose }) => {
          if (!isOpen) return null
          return (
            <div onDragStart={stopPropagation}>
              <DiscoModal
                testid={"EditDashboardBlockButton.modal"}
                title={`Edit ${name}`}
                modalContentLabel={`Edit ${name}`}
                isOpen
                onClose={onClose}
                buttons
                width={"720px"}
                maxWidth={"90vw"}
                body={
                  <EditDashboardBlockForm
                    dashboardBlockKey={dashboardBlock}
                    onClose={onClose}
                  />
                }
              />
            </div>
          )
        },
        [dashboardBlock, name]
      )}
    >
      {children}
    </OverridableDiscoButton>
  )
}

export default EditDashboardBlockButton
