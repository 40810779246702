import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useDashboardContext } from "@/dashboard/util/DashboardContext"
import { useCanEditDashboardFragment$key } from "@/dashboard/util/__generated__/useCanEditDashboardFragment.graphql"
import useCanEditDashboard from "@/dashboard/util/useCanEditDashboard"
import { useIsMobile } from "@utils/hook/screenSizeHooks"

/** Can the dashboard be customized */
export function useCanCustomizeDashboard(
  dashboardKey?: useCanEditDashboardFragment$key | null
) {
  const isMobile = useIsMobile()
  const activeProduct = useActiveProduct()
  const canEdit = useCanEditDashboard(dashboardKey)
  const context = useDashboardContext()

  // Only those with permission can customize dashboards
  if ((dashboardKey && !canEdit) || (context && !context.canEdit)) return false

  // Cannot customize dashboards on mobile
  if (isMobile) return false

  // Cannot customize two column experience dashboards
  if (activeProduct?.dashboard?.layout === "two_column") return false

  // Cannot customize pathway dashboards
  if (activeProduct?.type === "pathway") return false

  return true
}
