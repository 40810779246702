/**
 * @generated SignedSource<<7009ee5f2d1b6be9f7aa5bca8a8fb734>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DashboardBlockContentMode = "preview" | "full" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentDashboardBlockFragment$data = {
  readonly mode: DashboardBlockContentMode;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockItemTemplateFragment" | "ContentDashboardBlockPreviewContentFragment" | "ContentDashboardBlockFullContentFragment">;
  readonly " $fragmentType": "ContentDashboardBlockFragment";
};
export type ContentDashboardBlockFragment$key = {
  readonly " $data"?: ContentDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mode",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentDashboardBlockPreviewContentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentDashboardBlockFullContentFragment"
    }
  ],
  "type": "ContentDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "05893c555b32e9e9ffe07aa187831f37";

export default node;
