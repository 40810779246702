/**
 * @generated SignedSource<<e0c8d506593470bafb78f696474f26fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RichTextDashboardBlockFragment$data = {
  readonly position: DashboardBlockPosition;
  readonly richTextBlockTitle: string | null;
  readonly richEditorContent: string | null;
  readonly hasCtaButton: boolean | null;
  readonly ctaUrl: string | null;
  readonly ctaText: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "RichTextDashboardBlockFragment";
};
export type RichTextDashboardBlockFragment$key = {
  readonly " $data"?: RichTextDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RichTextDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RichTextDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": "richTextBlockTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorContent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCtaButton",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaText",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "RichTextDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "a5bda75eb13d50209c9adcb30153678a";

export default node;
