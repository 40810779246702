/**
 * @generated SignedSource<<7a44c45442880e1bc858a7a6d77d65ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurriculumDashboardBlockListViewFragment$data = {
  readonly productId: string | null;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "CurriculumDashboardBlockListViewFragment";
};
export type CurriculumDashboardBlockListViewFragment$key = {
  readonly " $data"?: CurriculumDashboardBlockListViewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurriculumDashboardBlockListViewFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurriculumDashboardBlockListViewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "CurriculumDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "9fcfa92284bbb02d4f8b50981c8cb1aa";

export default node;
