import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { DiscoCheckbox, DiscoFormControl } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form: DashboardBlockFormStore
}

function DashboardBlockShowOnMobileField({ form }: Props): JSX.Element {
  return (
    <DiscoFormControl>
      <DiscoCheckbox
        label={"Hide this block on mobile"}
        checked={!form.state.showOnMobile}
        onChange={() => (form.state.showOnMobile = !form.state.showOnMobile)}
      />
    </DiscoFormControl>
  )
}

export default observer(DashboardBlockShowOnMobileField)
