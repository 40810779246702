/**
 * @generated SignedSource<<54ede0f2ccb6849a0795cbff91c0f679>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChannelsDashboardBlockPaginationFragment$data = {
  readonly chatChannels: {
    readonly __id: string;
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly externalChannelId: string;
        readonly productApp: {
          readonly id: string;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"ChannelsDashboardBlockItemFragment">;
      };
    }>;
  };
  readonly id: string;
  readonly " $fragmentType": "ChannelsDashboardBlockPaginationFragment";
};
export type ChannelsDashboardBlockPaginationFragment$key = {
  readonly " $data"?: ChannelsDashboardBlockPaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChannelsDashboardBlockPaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "chatChannels"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ChannelsDashboardBlockPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ChannelsDashboardBlockPaginationFragment",
  "selections": [
    {
      "alias": "chatChannels",
      "args": [
        {
          "kind": "Literal",
          "name": "isDashboard",
          "value": true
        }
      ],
      "concreteType": "ChatChannelNodeConnection",
      "kind": "LinkedField",
      "name": "__ChannelsDashboardBlock__chatChannels_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatChannelNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatChannel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "externalChannelId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductApp",
                  "kind": "LinkedField",
                  "name": "productApp",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ChannelsDashboardBlockItemFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": "__ChannelsDashboardBlock__chatChannels_connection(isDashboard:true)"
    },
    (v1/*: any*/)
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "6d9202597c712b31308db7cc01c916ce";

export default node;
