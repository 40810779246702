/**
 * @generated SignedSource<<6bbf0e502d28c5fb6ef95b49aeb95ab4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChannelsDashboardBlockFragment$data = {
  readonly productId: string | null;
  readonly title: string;
  readonly position: DashboardBlockPosition;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "ChannelsDashboardBlockFragment";
};
export type ChannelsDashboardBlockFragment$key = {
  readonly " $data"?: ChannelsDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChannelsDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChannelsDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "ChannelsDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "8d9a1df1aebee0dd1a968320dc898c04";

export default node;
