/**
 * @generated SignedSource<<7e70cab57a0b7120f87bd7d2d7313255>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WelcomeBannerDashboardBlockFragment$data = {
  readonly title: string;
  readonly subtitle: string;
  readonly richEditorDescription: string;
  readonly cover: string | null;
  readonly organization: {
    readonly cover: string | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "WelcomeBannerDashboardBlockFragment";
};
export type WelcomeBannerDashboardBlockFragment$key = {
  readonly " $data"?: WelcomeBannerDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WelcomeBannerDashboardBlockFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WelcomeBannerDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subtitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorDescription",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "WelcomeBannerDashboardBlock",
  "abstractKey": null
};
})();

(node as any).hash = "326f452d4f5eb06d0a5edffc5e220f84";

export default node;
