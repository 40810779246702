import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { DashboardBlockFeedView } from "@/dashboard/blocks/kinds/__generated__/FeedDashboardBlockFragment.graphql"
import CarouselView from "@assets/images/covers/dashboard-blocks/carousel-view.svg"
import ScrollView from "@assets/images/covers/dashboard-blocks/scroll-view.svg"
import { DiscoAlert, DiscoDivider, DiscoFormControl, DiscoInput } from "@disco-ui"
import DiscoRadioWithImage from "@disco-ui/radio/DiscoRadioWithImage"
import { RadioGroup } from "@material-ui/core"
import { observer } from "mobx-react-lite"

interface Props {
  form: DashboardBlockFormStore
}

function FeedDashboardBlockForm(props: Props) {
  const { form } = props
  const { feed } = form.state
  const experienceLabel = useLabel("admin_experience")
  const activeProduct = useActiveProduct()
  const classes = useStyles()

  if (!feed) return null

  return (
    <>
      <DiscoAlert
        message={`Only one Feed block can be added. This block will aggregate all feeds across this ${
          activeProduct ? experienceLabel.singular : "community"
        }.`}
      />
      <DiscoDivider marginTop={2.5} marginBottom={2} />
      <DiscoFormControl label={"Title"} errorMessages={form.errorsByField.title}>
        <DiscoInput
          value={feed.title}
          onChange={(e) => (feed.title = e.target.value)}
          fullWidth
        />
      </DiscoFormControl>

      <DiscoFormControl>
        <DiscoFormControl label={"Feed style"}>
          <RadioGroup
            value={feed.view}
            onChange={(e) => (feed.view = e.target.value as DashboardBlockFeedView)}
            className={classes.radioGroup}
          >
            <DiscoRadioWithImage
              testid={`FeedDashboardBlockForm.radio.card`}
              className={classes.radioButton}
              label={"Carousel"}
              sublabel={<CarouselView />}
              value={"card"}
              checked={feed.view === "card"}
            />

            <DiscoRadioWithImage
              testid={`FeedDashboardBlockForm.radio.list`}
              className={classes.radioButton}
              label={"Scrollable"}
              sublabel={<ScrollView />}
              value={"list"}
              checked={feed.view === "list"}
            />
          </RadioGroup>
        </DiscoFormControl>
      </DiscoFormControl>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  radioGroup: {
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: theme.spacing(2),
    display: "grid",
    gridAutoFlow: "column",
    flex: "1 1 auto",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  radioButton: {
    flexGrow: 1,
  },
}))

export default observer(FeedDashboardBlockForm)
