import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { ExperienceDetailsMembersQuery } from "@/dashboard/blocks/kinds/experience-details/__generated__/ExperienceDetailsMembersQuery.graphql"
import Relay from "@/relay/relayUtils"
import UserIcon from "@/core/ui/iconsax/linear/user.svg"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { graphql, useLazyLoadQuery } from "react-relay"

function ExperienceDetailsMembers() {
  const activeProduct = useActiveProduct()
  const classes = useStyles()
  const membersLabel = useLabel("product_member")
  const theme = useTheme()

  const { product } = useLazyLoadQuery<ExperienceDetailsMembersQuery>(
    graphql`
      query ExperienceDetailsMembersQuery($id: ID!) {
        product: node(id: $id) {
          id
          ... on Product {
            productMemberships {
              totalCount
            }
          }
        }
      }
    `,
    { id: activeProduct?.id || "" }
  )

  const membersCount = product?.productMemberships?.totalCount

  if (!product) return null

  return (
    <div className={classes.container}>
      <UserIcon color={theme.palette.text.disabled} width={24} />
      <DiscoText
        variant={"body-sm"}
        color={"text.secondary"}
        marginLeft={1}
      >{`${membersCount} ${
        membersCount === 1 ? membersLabel.singular : membersLabel.plural
      }`}</DiscoText>
    </div>
  )
}

const useStyles = makeUseStyles({
  container: {
    display: "flex",
    alignItems: "center",
  },
})

const ExperienceDetailsMembersSkeleton: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Skeleton variant={"circle"} width={24} />
      <DiscoTextSkeleton variant={"body-sm"} marginLeft={1} width={75} />
    </div>
  )
}

export default Relay.withSkeleton({
  component: ExperienceDetailsMembers,
  skeleton: ExperienceDetailsMembersSkeleton,
})
